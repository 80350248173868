import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import Moment from "react-moment";
import 'moment/locale/nb';
import Layout from "../components/Layout";
import Markdown from "react-markdown";
import "./SinglePost.css"



export const query = graphql`
  query ArticleQuery($slug: String!) {
    strapiArticle(slug: { eq: $slug }, status: { eq: "published" }) {
      strapiId
      title
      description
      content
      publishedAt
      image {
        publicURL
        childImageSharp {
          fixed(width: 1200, height: 720) {
            src
          }
        }
      }
    }
  }
`;

const Article = ({ data }) => {
  const article = data.strapiArticle;
  const seo = {
    metaTitle: article.title,
    metaDescription: article.description,
    shareImage: article.image,
    article: true,
  };

  return (
    <Layout seo={seo}>
      <div>
      <article
        className="SinglePost section light"
        itemScope
        itemType="http://schema.org/BlogPosting"
      >
        <div className="container skinny">
          <div className="SinglePost--Content relative space-100t">

              <h1 className="SinglePost--Title" itemProp="title">
                {article.title}
              </h1>
              <div className="SinglePost--Meta">
              <Moment format="Do MMMM YYYY" locale="nb">{article.published_at}</Moment>
              </div>
              <div className="SinglePost--Excerpt">
              <p>
                {article.description}
              </p>
              </div>

            <Img
              fixed={article.image.childImageSharp.fixed}
              imgStyle={{ position: "static" }}
              className={'featured-image container space-50b'}
            />
            <div className="SinglePost--InnerContent links">
              <Markdown source={article.content} escapeHtml={false} />
            </div>
          </div>
        </div>
      </article>

      </div>
    </Layout>
  );
};

export default Article;
